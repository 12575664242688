<template>
	<div>
		<div class="mb-4 mt-5 text-center">
			<h2 class="ion-margin-bottom ion-text-center">Obnovenie hesla</h2>
		</div>

		<form @submit.prevent="resetPassword()" @keydown.enter="resetPassword()">
			<cmf-input :icon="mail" class="input" v-model="v$.form.email" :vBe="vBe" placeholder="Email"  />
			<cmf-input :icon="qrCode" class="input" v-model="v$.form.code" :vBe="vBe" placeholder="Bezpečnostný kód"  />
			<cmf-input :icon="lockClosed" class="input" v-model="v$.form.password" :vBe="vBe" placeholder="Heslo" type="password" />
			<cmf-input :icon="lockClosed" class="input" v-model="v$.form.password_confirmation" :vBe="vBe" placeholder="Zopakujte heslo" type="password" />

			<ion-button type="submit" expand="block" color="primary" fill="solid" shape="round" class="mb-3">Zmeniť heslo</ion-button>

			<p @click="$router.go(-1)" pointer class="text-center">Ísť <b class="z-primary-font-color">späť</b></p>
		</form>

	</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@vuelidate/validators'
import { mail, qrCode, lockClosed } from 'ionicons/icons'

export default {
	props: {
		navigateNext: {
			type: String,
			default: 'login'
		}
	},

	data() {
		return {
			v$: useVuelidate(),
			vBe: [],
			form: {},
			mail,
			qrCode,
			lockClosed
		}
	},
	validations(){
		return{
			form:{
				code: {required},
				email: {required, email},
				password: {required, minLength: minLength(8)},
				password_confirmation: {required, minLength: minLength(8), sameAsPassword: sameAs(this.form.password)}
			}
		}
	},
	created() {
		this.form.email = this.$route.params.email || new URLSearchParams(window.location.search.substring(1)).get('email')
		this.form.code = new URLSearchParams(window.location.search.substring(1)).get('code')
	},

	methods: {
		async resetPassword() {
			if (!(await this.v$.$validate())) return
			this.$wLoader.startLoading(this.$t('Changing password...'))

			try {
				await this.$store.dispatch('wAuth/resetPassword', this.form)
				this.$router.push({name: this.navigateNext})
			} catch (error) {
				this.$wToast.error(error)
			}

			this.$wLoader.clearLoading()
		}
	},

	watch: {
		$route(to) {
			if (to.name == 'resetpassword') {
				this.form = {}
				setTimeout(() => { this.v$.$reset() }, 10)
			}
		}
	}
}
</script>
